* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: monospace;
}

pre {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif;
}

html,
body,
#root {
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 20% 30% 50%;
  /* grid-template-rows: 30% 70%; */
  grid-template-areas: " name content1 header " " icons content1 body";
}
.hline {
  border-bottom: 2px solid rgb(255, 193, 50);
  padding-top: 10px;
  padding-bottom: 10px;
}
#grid-header {
  grid-area: header;
  height: 150px;
  border-bottom: 2px solid white;
  margin-top: 50px;
}

.grid-content {
  grid-area: content1;
  margin-top: 50px;
}

#grid-header li {
  list-style-type: none;
  display: inline-block;
  margin-right: 40px;
  text-decoration: none;
}

#grid-header li a {
  text-decoration: none;
  transition: 0.3s;
  display: block;
}

#grid-header li a:visited {
  color: black;
}

#grid-header li a:active {
  color: black;
}

img {
  max-width: 100%;
  max-height: 100%;
}

#img-container {
  position: relative;
  width: 250px;
  height: 390px;
}

#img-container img {
  position: absolute;
  bottom: 0;
  left: 0;

  /* -webkit-filter: drop-shadow(5px 5px 5px rgb(18, 17, 17)); */
}

#id1 {
  z-index: 4;
}
#id2 {
  z-index: 3;
  filter: blur(5px);
}
.grid-body {
  grid-area: body;
}

#body-content {
  border-left: 2px solid white;
  padding: 20px;
  color: white;
}

#body-content ul {
  list-style-position: inside;
}

.grid-icon {
  grid-area: icons;
  margin-left: 10px;
  margin-bottom: 10px;
}

.grid-icon {
  margin-left: auto;
  margin-right: auto;
}

.icon-class {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.icons {
  /* filter:invert(100%); */
  width: 80%;
}

#name-overlay {
  position: absolute;
  /* Sit on top of the page content */
  top: 15px;
  left: 40px;
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  font-size: 40px;
  font-weight: bold;
  color: white;
}

#background {
  position: fixed;
  /* Sit on top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgb(255, 229, 215); */
  z-index: -1;
}

.main-container {
  /* height: 100%; */
}

.first-container {
  background: rgb(255, 193, 50);
  opacity: 20px;
  width: 100%;
}
.section {
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 10px;
}
.header-name {
  color: white;
  font-size: 15px;
}

.second-container {
  /* background-color: rgb(247, 206, 206); */
  height: 100%;
  display: flex;
  justify-content: center;
  width: 70%;
  margin: auto;
  padding: 10px;
}

#aboutme {
  margin-top: 10px;
}

#aboutme h2 {
  margin-top: 20px;
}

#aboutme h3 {
  margin-top: 10px;
}

#innerUi {
  padding-left: 40px;
}

.third-container {
  /* background-color: rgb(202, 228, 183); */
  height: 80%;
  display: block;
  justify-content: center;
  width: 70%;
  margin: auto;
  padding: 10px;
}

/* Add padding BETWEEN each column */
.project {
  margin: auto;
  border: 1px solid rgb(255, 193, 50);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  transition: box-shadow 0.3s;
  overflow: auto;
}
.project-container {
  width: 800px;
}
.project a {
  text-decoration: none;
  color: black;
}
.ulElement {
  list-style: none;
}
.project:hover,
.project:focus {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}
.ulElement li {
  display: inline;
  list-style-type: none;
  padding-right: 20px;
  font-size: 12px;
  font-style: italic;
  float: right;
  margin-top: 10px;
  color: rgb(255, 193, 50);
}

.fourth-container {
  /* background-color: rgb(50, 201, 178); */
  height: 80%;
  display: block;
  justify-content: center;
  width: 70%;
  margin: auto;
  padding: 10px;
}
.blog-class {
  margin: auto;
  padding: 10px;
  margin-top: 10px;
  transition: box-shadow 0.3s;
  height: 20%;
  overflow: auto;
}
.blog-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.blog-class:hover {
  box-shadow: 0 0 11px rgb(255, 193, 50);
}
.blog-img {
  height: 75px;
  width: 75px;
  float: left;
  /* TODO fix this to scale properly */
  margin-left: 50px;
}

.blog-content {
  height: 100%;
  float: right;
  width: 70%;
  position: relative;
}
.blog-content > h4 {
  color: rgb(255, 193, 50);
  font-weight: bold;
}
.blog-content > p {
  font-style: italic;
  font-size: small;
  color: grey;
}
.title-a {
  text-decoration: none;
  color: black;
}
.blog-a {
  text-decoration: none;
  color: rgb(255, 193, 50);
  /* font-family: sacramento; */
  font-weight: bold;
  font-size: 20px;
}
.more-blogs {
  margin-top: 20px;
  text-align: center;
}
.more-blogs p {
  display: inline-block;
  padding-right: 5px;
}
#more-img {
  height: 18px;
  width: 18px;
  display: inline-block;
}
.more-blogs a {
  color: rgb(255, 193, 50);
  text-decoration: none;
}
.content {
  background-color: white;
  padding: 10px;
  height: 250px;
}

.content h3 {
  font-weight: bold;
}

.footer {
  background: rgb(255, 193, 50);
  height: 50px;
}
.footer p {
  color: white;
  opacity: 0.6;
  text-align: center;
}

/* For tablets screens */
@media screen and (max-width: 900px) and (min-width: 601px) {
  #img-container {
    position: relative;
    width: 175px;
    height: 390px;
  }
  .column {
    width: 50%;
  }
}

/* For phones screens */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }

  #header-name {
    float: none;
    display: block;
    text-align: left;
  }
  .menu {
    float: none;
  }
  /* for Raghad name at the top */
  #name-overlay {
    position: absolute;
    /* Sit on top of the page content */
    top: 15px;
    left: 20px;
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    font-size: 22px;
    color: white;
  }
  /* for Raghad image */
  #img-container {
    position: static;
    width: auto;
    height: auto;
  }

  #img-container img {
    max-width: none;
    max-height: none;
    position: static;
    width: 250px;
    height: auto;
  }

  #id2 {
    display: none;
  }

  /* holds the image */
  .grid-content {
    margin-top: 10px;
    overflow: hidden;
    width: 250px;
    height: 250px;
    margin: auto;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 10% 30% 60%;
    /* grid-template-rows: 30% 70%; */
    grid-template-areas: " name name header " " body body body" " icons content1 content1 ";
  }

  #grid-header {
    height: 50px;
    border-bottom: none;
  }

  .grid-icon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
  }

  .grid-body {
    margin-left: 30px;
    margin-right: 30px;
    border-top: 2px solid white;
  }

  .second-container {
    width: 90%;
  }
  /* for the blog at the home */
  .blog-img {
    height: 50px;
    width: 50px;
    float: left;
    margin-left: 0px;
  }
  .blog-content {
    height: 100%;
    float: right;
    width: 70%;
    position: relative;
    padding-right: 0px;
    padding: 0px;
    margin: 0px;
  }
}
