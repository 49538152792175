.blog-main-container {
  height: 100%;
}
.blog-menue {
  height: 100px;
}
.blog-first-section {
  margin: auto;
  padding: 10px;
  margin-top: 80px;
}
.card-container {
  display: flex;
  justify-content: center;
  height: 220px;
}
.cardItem {
  height: 149px;
  width: 141px;
  padding: 20px;
  text-align: center;
  transition: all 500ms ease-in-out;
  background-color: white;
  border-radius: 12px;
  border-style: solid;
  border-color: rgb(255, 193, 50);
}
.cardItem:hover {
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
}
.cardLabel {
  margin-top: 24px;
  width: 100%;
  font-size: 16px;
  display: block;
}
.blog-page-title {
  color: #000000;
  text-align: center;
  padding: 20px;
}
.blog-title-text-yellow {
  color: rgb(255, 193, 50);
}
.blog-title {
  text-align: center;
  font-weight: bold;
}
.blog-content-p {
  font-size: 12px;
  padding: 20px;
}

.first-container-pp {
  background: rgb(255, 193, 50);
  opacity: 20px;
  width: 100%;
  height: 150px;
}
#blog-footer {
  margin-top: 80px;
}

.blog-date {
  font-style: italic;
  font-size: small;
  color: grey;
}
.sinlge-blog-content {
  margin: 20px;
  border-bottom: 1px solid rgb(255, 193, 50);
  padding: 15px;
  padding-bottom: 30px;
}

.read-more {
  margin-left: 15px;
}
.read-more a {
  color: rgb(255, 193, 50);
  text-decoration: none;
}
.blog-head a {
  color: black;
  font-weight: bold;
  font-size: 19px;
  text-decoration: none;
}
.blog-text {
  margin-top: 20px;
  color: #989898;
  font-size: 14px;
}
.single-blog-body {
  margin: 30px;
}
