.about-container {
  width: 900px;
}

.about-section {
  display: grid;
  grid-template-areas: "about-title about-content";
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  margin-top: 30px;
}

.about-title {
  grid-area: about-title;
  border-right: 2px solid rgb(255, 193, 50);
  text-align: right;
  padding: 20px;
}

.about-content {
  grid-area: about-content;
  padding: 10px;
}

.about-item {
  display: grid;
  grid-template-areas: "about-item-content about-item-date";
  grid-template-columns: 5fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  padding: 10px;
}
.about-item-content {
  grid-area: about-item-content;
}

.about-item-date {
  grid-area: about-item-date;
  font-style: italic;
  padding: 0 5px 0 0;
  font-size: 12px;
  color: #999;
  /* margin-top: 15px; */
}

.about-item-content > ul,
li {
  font-size: 10pt;
}
.about-item-content > ul {
  padding: 5px;
}
.about-item-content-heading {
  margin-top: 10px;
}

.about-item-title {
  font-size: 14px;
  font-weight: 900;
}

.about-item-subtitle {
  font-size: 12px;
}

@media screen and (max-width: 600px) {
  .about-section {
    grid-template-areas: "about-title" "about-content";
    grid-template-columns: 1fr;
    margin-top: 0px;
  }
  .about-title {
    border-bottom: 2px solid rgb(255, 193, 50);
    text-align: left;
    border-right: none;
    padding: 10px;
  }
}
